import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import compact from 'lodash/compact';
import get from 'lodash/get';
import { graphql, PageProps } from 'gatsby';

import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import Divider from '../../components/Divider';
import InfiniteScroll from '../../components/InfiniteScroll';
import Card from '../../components/Card';
import SEO from '../../components/SEO/SEO';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import { EnglishTestsIndexQuery } from '../../../graphql.schema';
import { GlobalPageContext, PaginationContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './index.module.scss';

type PageContextType = GlobalPageContext & PaginationContext;

const EnglishTestsIndex: React.FC<PageProps<EnglishTestsIndexQuery, PageContextType>> = ({ data, pageContext }) => {
  const { englishTests = [] } = data.strapi;
  const pages = get(data, 'strapi.pages', []);
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const [list, setList] = useState(englishTests);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    setList(englishTests.slice(0, 10));
    setIsInitialLoadDone(true);
  }, [englishTests]);

  if (!websiteLocale) return null;
  const showFooterCta = pages[0]?.pageSettings?.showFooterCta ?? false;
  const footerCta = pages[0]?.pageSettings?.customFooterCta?.documentId
    ? pages[0]?.pageSettings?.customFooterCta
    : websiteLocale.footerCta;
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={pages[0]?.seo?.metaTitle ?? pages[0]?.pageSettings?.title ?? intl.formatMessage({ id: 'seo.englishTests.title' })}
        description={pages[0]?.seo?.metaDescription ?? pages[0]?.pageSettings?.metaDescription ?? intl.formatMessage({ id: 'seo.englishTests.description' })}
        image={pages[0]?.seo?.metaImage?.url}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />
      <div className={classnames('container', styles.root)}>
        <div className="columns">
          <div className="column is-5">
            <h1 className="title color-a mt-6 mb-0">
              <FormattedMessage id="englishTestsIndexHeading" defaultMessage="English Tests" />
            </h1>
          </div>
        </div>

        <Divider paddingTop="70px" marginBottom="75px" />

        {!englishTests?.length ? (
          <div className={classnames('column is-5 p-0 mt-10', styles.noEnglishTests)}>
            <h4 className="title is-4">
              <FormattedMessage id="noEnglishTestsIndexHeading" defaultMessage="No current English Tests" />
            </h4>
            <p>
              <FormattedMessage
                id="noEnglishTestsIndexDescription"
                defaultMessage="We do not have any English Tests Available"
              />
            </p>
          </div>
        ) : null}
        <div className={styles.gridWrapper}>
          {compact(list).map(({ documentId, name, slug, excerpt }) => {
            if (!slug) return null;
            return (
              <Card
                urlText={intl.formatMessage({ id: 'englishTestCardText', defaultMessage: 'View Test' })}
                slugBase={pageContext.pathPrefixes.english_tests}
                key={`english-test-${documentId}`}
                short={excerpt ?? ''}
                title={name ?? 'N/A'}
                slug={slug}
              />
            );
          })}
        </div>
        {isInitialLoadDone &&
          <InfiniteScroll
            completeList={englishTests}
            list={list}
            setList={(list) => setList(list)}
            selectedCategories={[]}
            categoryList={[]}
            filteredList={[]}
          />
        }
      </div>
      <div className={classnames('page-components')}>
        {pages[0]?.components &&
          pages[0]?.components?.map((component: any, idx: any) =>
            component ? (
              <ComponentRenderer
                hasRTL={websiteLocale?.hasRightToLeftLanguage}
                component={component}
                key={`page-component-${component.__typename}-${idx}`}
              />
            ) : null,
          )}
      </div>
      {footerCta && showFooterCta && <StrapiFooterCtaComponent {...footerCta} />}
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(EnglishTestsIndex));

export const query = graphql`
query EnglishTestsIndex($websiteLocaleId: ID!, $limit: Int!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    englishTests(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}}
      sort: "name:asc"
      locale: $locale
      pagination: {limit: $limit}
    ) {
      data {
        documentId
        attributes {
          excerpt
          name
          slug
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
        }
      }
    }
    pages(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}, index_page: {eq: "EnglishTests"}}
      locale: $locale
    ) {
      data {
        documentId
        attributes {
          title
          slug
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;
